<template>
  <div class="currency-list">
    <el-card class="table-card">
      <el-table
        class="table"
        @row-click="e => $emit('select', e.uuid)"
        @sort-change="e => $emit('sort', e)"
        :data="filterData"
      >
        <el-table-column
          label="作成日"
          align="center"
          prop="created_at"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          label="地域"
          align="center"
          prop="region"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          label="通貨名"
          align="center"
          prop="name"
        ></el-table-column>
        <el-table-column
          label="通貨単位"
          align="center"
          prop="unit"
        ></el-table-column>
        <el-table-column
          label="説明"
          align="center"
          prop="description"
        ></el-table-column>
        <el-table-column align="right">
          <template v-slot:header>
            <el-input
              v-model="inputData"
              size="medium"
              prefix-icon="el-icon-search"
              placeholder="通貨を検索"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
const dateFormatter = date => {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  return `${y}/${m}/${d}`;
};

export default {
  data() {
    return {
      inputData: '',
    };
  },
  props: {
    currencyData: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      default: 600,
    },
  },
  computed: {
    filterData() {
      return this.currencyData
        .filter(x => x.name.match(this.inputData))
        .map(({ created_at, ...x }) => ({
          ...x,
          created_at: dateFormatter(created_at),
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
.currency-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.search-form {
  width: 100%;
}

.table-card {
  width: 100%;
}
</style>
