<template>
  <div class="currency-list-page">
    <currency-list-page
      :currency-data="currencyList"
      @select="select"
      @sort="sortChange"
    />
  </div>
</template>

<script>
import CurrencyListPage from '@/components/CurrencyListPage/CurrencyListPage';
import { mapActions, mapGetters } from 'vuex';
import { ActionTypes as CurrencyListActions } from '@/store/modules/currencyList/actions';
import { ActionTypes as RootActions } from '@/store/actions';

export default {
  name: 'CurrencyList',
  components: {
    CurrencyListPage,
  },
  data() {
    return {
      currencyList: [],
      prop: 'terminalId',
      order: null,
    };
  },
  methods: {
    ...mapActions([
      RootActions.getCurrencyInfo,
      RootActions.setCurrentCurrency,
    ]),
    ...mapActions('currencyList', [CurrencyListActions.getCurrencies]),
    async select(uuid) {
      try {
        await this.setCurrentCurrency({
          id: uuid,
          currentRoute: '/graph',
        });
        await this.$router.push('/graph');
        this.setCurrencyIdQuery(uuid);
      } catch (e) {
        console.error(e);
      }
    },
    setCurrencyIdQuery(id) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          id,
        },
      });
    },
    sortChange(e) {
      if (!e.order) {
        this.prop = 'terminalId';
      } else {
        const { prop, order } = e;
        this.prop = prop;
        this.order = order;
      }
      this.currencyList = this.changeList();
    },
    changeList() {
      switch (true) {
        case this.prop == 'terminalId':
          return this.currenciesByTerminal;
        case this.prop == 'region' && this.order == 'ascending':
          return this.currenciesByRegion;
        case this.prop == 'region' && this.order == 'descending':
          return [...this.currenciesByRegion].reverse();
        case this.prop == 'created_at' && this.order == 'ascending':
          return this.currenciesByDate;
        case this.prop == 'created_at' && this.order == 'descending':
          return [...this.currenciesByDate].reverse();
        default:
          return this.currenciesByTerminal;
      }
    },
  },
  computed: {
    ...mapGetters('currencyList', {
      currenciesByTerminal: 'currencyList',
      currenciesByDate: 'currencyListByDate',
      currenciesByRegion: 'currencyListByRegion',
    }),
  },
  async mounted() {
    await this.getCurrencies();
    this.currencyList = this.currenciesByTerminal;
  },
};
</script>

<style lang="scss" scoped>
.currency-list-page {
  display: flex;
  justify-content: center;
}
</style>
